import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AuthContextProvider from './contexts/Auth.js';
import EventsContextProvider from './contexts/EventsContext';
import IframeContextProvider from './contexts/IframeContext';
import ProfileContextProvider from './contexts/Profile.js';
import ProfilesContextProvider from './contexts/Profiles.js';
import SignupContextProvider from './contexts/Signup.js';
import SnackbarContextProvider from './contexts/Snackbar';
import WalkthroughContextProvider from './contexts/Walkthrough.js';
import './index.css';
import ThemeProvider from './theme';

mixpanel.init('44f5dc763b6812b995b5ddb6b72112e0', {
  // debug: process.env.NODE_ENV === 'development',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthContextProvider>
        <ProfilesContextProvider>
          <SignupContextProvider>
            <ProfileContextProvider>
              <ThemeProvider>
                <EventsContextProvider>
                  <IframeContextProvider>
                    <SnackbarContextProvider>
                      <WalkthroughContextProvider>
                        <BrowserRouter>
                          <App />
                        </BrowserRouter>
                      </WalkthroughContextProvider>
                    </SnackbarContextProvider>
                  </IframeContextProvider>
                </EventsContextProvider>
              </ThemeProvider>
            </ProfileContextProvider>
          </SignupContextProvider>
        </ProfilesContextProvider>
      </AuthContextProvider>
    </LocalizationProvider>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
