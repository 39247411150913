import { LoadingButton } from '@mui/lab';
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Conditions from '../components/Conditions';
import OnboardingLayoutOld from '../components/OnboardingLayoutOld';
import { AuthContext } from '../contexts/Auth';
import { ProfilesContext } from '../contexts/Profiles';
import { SignupContext } from '../contexts/Signup';

const FORM = {
  width: '100%',
};

const SignupStep1 = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const { profiles, creatingProfile } = useContext(ProfilesContext);

  const {
    forWho,
    setForWho,
    name,
    setName,
    setConditions,
    conditions,
    relationship,
    setRelationship,
    createProfile,
    loading,
    sex,
    setSex,
    age,
    setAge,
    setAfterSignup,
    setOrganization,
  } = useContext(SignupContext);

  const navigate = useNavigate();

  const canContinue = () => {
    if (forWho === 1 && name.length < 3) return false;
    if (relationship === 1 && relationship !== null) return false;
    if (conditions === null) return false;
    if (sex === null) return false;
    if (age === null) return false;
    // if (conditions.length === 0) return false;
    return true;
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      profiles &&
      profiles.length > 0 &&
      !creatingProfile
    ) {
      setAfterSignup(true);
      navigate('/dashboard/medications');
    }
  }, [isAuthenticated, profiles, navigate, creatingProfile]);

  return (
    <OnboardingLayoutOld>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Start your story now
        </Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 500, color: 'gray.800' }}>
          {forWho === 0
            ? 'Create your profile!'
            : 'Create a profile for someone you care for!'}
        </Typography>
        <div></div>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
          <Typography
            sx={{
              textAlign: 'start',
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
            }}
          >
            Who is this for?
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={forWho}
              onChange={(e) => setForWho(e.target.value)}
              sx={{ textAlign: 'start' }}
            >
              <MenuItem value={0}>Myself</MenuItem>
              <MenuItem value={1}>Someone I care for</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {forWho === 1 && (
          <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{
                textAlign: 'start',
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
              }}
            >
              Name of the person you care for?
            </Typography>
            <TextField value={name} onChange={(e) => setName(e.target.value)} />
          </Stack>
        )}
        {forWho === 1 && (
          <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{
                textAlign: 'start',
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
              }}
            >
              What is your relationship to them?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
                sx={{ textAlign: 'start' }}
              >
                <MenuItem value={'family'}>Family</MenuItem>
                <MenuItem value={'friend'}>Friend</MenuItem>
                <MenuItem value={'doctor'}>Doctor</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        )}
        <Conditions
          forWho={forWho}
          value={conditions || []}
          setValue={setConditions}
        />
        <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
          <Typography
            sx={{
              textAlign: 'start',
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
            }}
          >
            {forWho === 1
              ? 'What is their biological sex?'
              : 'What is your biological sex?'}
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              sx={{ textAlign: 'start' }}
            >
              <MenuItem value={'male'}>Male</MenuItem>
              <MenuItem value={'female'}>Female</MenuItem>
              <MenuItem value={'none'}>Prefer not to say</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
          <Typography
            sx={{
              textAlign: 'start',
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
            }}
          >
            {forWho === 1 ? 'How old are they?' : 'How old are you?'}
          </Typography>
          <TextField
            value={age}
            type="number"
            onChange={(e) => setAge(e.target.value)}
          />
        </Stack>
        <LoadingButton
          onClick={() => {
            createProfile();
          }}
          variant="contained"
          size="large"
          loading={loading}
          disabled={!canContinue()}
        >
          Finish Signup
        </LoadingButton>
        <Stack
          direction={'column'}
          spacing={1}
          sx={{
            p: 2,
            pl: 3,
            pr: 3,
            mt: 3,
            bgcolor: '#CFF5E3',
            textAlign: 'start',
          }}
        >
          <Box sx={{ fontWeight: 600, fontSize: 16 }}>
            Private and secure data
          </Box>
          <Box sx={{ fontSize: 14 }}>
            All data you enter on healthstory is <b>private</b> and{' '}
            <b>not shared</b> with anyone else. Read our{' '}
            <u>
              <a
                style={{ color: 'black' }}
                href="https://www.healthstoryai.com/privacy"
              >
                privacy policy
              </a>
            </u>{' '}
            for more information.
          </Box>
        </Stack>
      </Stack>
    </OnboardingLayoutOld>
  );
};

export default SignupStep1;
