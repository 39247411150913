import { Stack } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import * as React from 'react';
import { VariableSizeList } from 'react-window';
import diseases from '../assets/diseases.json';
import { SignupContext } from '../contexts/Signup';
import { track } from '../utils/analytics';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref,
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    'boxSizing': 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

let i = -1;

const getItem = () => {
  i += 1;
  return diseases[i].diseaseName;
};

const OPTIONS = Array.from(new Array(diseases.length)).map(() => getItem());

export default function Conditions({ setValue, value, forWho }) {
  const { organization } = React.useContext(SignupContext);
  const diseaseNamesToTags = (data) => {
    const x = data.map((item) => {
      return (
        diseases.find((i) => i.diseaseName.toLowerCase() === item.toLowerCase())
          ?.diseaseTag || item
      );
    });
    return x;
  };

  const diseaseTagsToNames = (data) => {
    const x = data.map((item) => {
      return (
        diseases.find((i) => i.diseaseTag.toLowerCase() === item.toLowerCase())
          ?.diseaseName || item
      );
    });
    return x;
  };

  return (
    <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
      <Typography
        sx={{
          textAlign: 'start',
          fontSize: 16,
          fontWeight: 500,
          color: '#0B3954',
        }}
      >
        {forWho !== 1
          ? `What ${
              organization === 'Story Catch' ? 'mental' : ''
            } health conditions have you been diagnosed with?`
          : `What ${
              organization === 'Story Catch' ? 'mental' : ''
            } health conditions has the person been diagnosed with?`}
      </Typography>
      <Autocomplete
        id="virtualize-demo"
        multiple
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        value={diseaseTagsToNames(value)}
        freeSolo
        options={OPTIONS}
        onChange={(_event, value) => {
          setValue(diseaseNamesToTags(value));
          track('signup_step_1_condition_selected', { conditions: value });
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            helperText="Press Enter to add a condition that is not in the list"
          />
        )}
        renderOption={(props, option, state) => [props, option, state.index]}
        //   renderGroup={(params) => params}
      />
    </Stack>
  );
}
